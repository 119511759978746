<template>

  <div id="download-modal">

    <div class="inner">

      <div class="drop">
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
    viewBox="0 0 105.3 112.6" enable-background="new 0 0 105.3 112.6" xml:space="preserve">
          <g>
            <path opacity="0.1" enable-background="new" d="M78,39.9c0,4.6,0,10.2,0,10.2h26.7l-52,60.3l-52-60.3h27.7
              c0,0-0.1-4.8-0.1-10.8C28.2,8.7,78,9.8,78,39.9z"/>
            <path fill="#FFDBD7" d="M78,23.4c0,4.6,0,10.3,0,10.3h26.7L52.7,94l-52-60.3h27.7c0,0-0.1-4.8-0.1-10.8C28.2-7.8,78-6.7,78,23.4z"
              />
            <g>
              <path fill="none" stroke="#5B4646" stroke-width="1.4173" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="435,435" d="
              M35,19.4c0,0,1.1,2.6,3.7,2.6c2.5,0,3.7-2.6,3.7-2.6s1,2.6,3.7,2.6s3.7-2.6,3.7-2.6S51,22,53.5,22s3.7-2.6,3.7-2.6
              s1.1,2.6,3.7,2.6c2.6,0,3.7-2.6,3.7-2.6s1.5,2.6,3.7,2.6s3.7-2.6,3.7-2.6"/>
              <path fill="none" stroke="#5B4646" stroke-width="1.4173" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="117,117" d="
              M47.3,34c0,0-1-3.8-4.7-3.8c-3.1,0-4.7,3.8-4.7,3.8"/>
              <path fill="none" stroke="#5B4646" stroke-width="1.4173" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="109,109" d="
              M68.5,33.8c0,0-1.3-3.5-4.4-3.5c-3,0-4.4,3.5-4.4,3.5"/>
              <path fill="none" stroke="#5B4646" stroke-width="1.4173" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="257,257" d="
              M42.9,40c0,0,2.3,8.2,10.2,8.2c8.3,0,10.2-8.2,10.2-8.2"/>
            </g>
          </g>
        </svg>
      </div>

      <p>
        Enjoyed using the <span class="title">Lazy Line Composer</span> to 
        animate & style your SVG?
      </p>

      <p>
        Please consider <span class="star" v-on:click="onStarClick"> 
          <iframe src="https://ghbtns.com/github-btn.html?user=camoconnell&repo=lazy-line-painter&type=star&size=large" frameborder="0" scrolling="0" width="76px" height="30px"></iframe>
          </span> on github <span class="link-out"> 
            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
   viewBox="0 0 73 71" enable-background="new 0 0 73 71" xml:space="preserve">
            <g>
              <polygon points="61.5,61.4 9.1,61.4 9.1,9 35.2,9 35.2,2.5 2.5,2.5 2.5,68 68,68 68,35.2 61.5,35.2  "/>
              <polygon points="41.8,2.5 51.2,11.9 32.7,30.5 40,37.8 58.6,19.3 68,28.7 68,2.5  "/>
            </g>
          </svg>
        </span>
      </p>

      <div class="download-btn">Downloading in {{seconds}} seconds</div>
    </div>
  </div> 

</template>

<script>

import Raf from '@/helpers/Raf'
import { mapState, mapMutations } from 'vuex';
import ResizeService from '@/services/ResizeService';  


export default {

    name: 'download-modal',

    data () {
      return {
        seconds: 10
      }
    },

    computed: {
      ...mapState('composer', [
        'countdown_duration'
      ])
    },

    components: {},

    mounted () {
      this.seconds = this.countdown_duration * 0.001;
      this.countdown();
    },

    methods:{

      ...mapMutations('composer', [
        'setDownloadCountdown'
      ]), 

      countdown() {
        setTimeout(()=>{

          this.seconds -= 1;

          if(this.seconds > 0){
            this.countdown();
          }

        }, 1000); 
      },

      enter(){ 
      },
      
      leave(){},

      onStarClick() {
        this.setDownloadCountdown(false);

        setTimeout(()=>{
          window.open('https://github.com/camoconnell/lazy-line-painter/','_blank');
        }, 1000)
      }
    
    },

    watch: {} 
} 
</script>


<style scoped lang="sass">

  #download-modal
    padding: 2rem
    background: rgba(181, 40, 123, 0.9)
    width: 100%
    height: 100%
    color: #601b43
    text-transform: initial 
    position: fixed
    display: flex
    align-items: center
    justify-content: center
    z-index: 999

    .link-out
      svg
        height: 18px
        fill: #c5bbb1
        margin-bottom: -2px

    .inner
      background-color: #f7f3ed
      border-radius: 2rem
      padding: 8rem 8rem 13rem
      width: 590px
      position: relative

      p
        margin-bottom: 2rem
        letter-spacing: 0.2rem
        font-size: 2.2rem
        line-height: 1.6
        font-family: 'melbournebold'
        color: #5c564f

        .title
          color: #bd3e87

    .star
      background: #e8e1da
      padding: 1rem
      display: inline-block
      border-radius: 9px
      height: 50px
      position: relative
      bottom: -8px
      position: relative
      margin: 0 0.5rem

      iframe
        pointer-events: none

    .drop
      position: absolute
      width: 100px
      margin-left: -50px
      top: -47px
      left: 50%

    .download-btn
      position: absolute
      width: 100%
      bottom: 0
      left: 0
      text-transform: none
      font-size: 2.4rem
      letter-spacing: 2px
      font-family: 'melbournereg' 
      margin: 0
      height: 100px
      color: #fff2f2
      display: flex
      align-items: center
      justify-content: center
      border-radius: 1rem
      background: #de6565
      border-top-left-radius: 0
      border-top-right-radius: 0
      transition: background-color 0.3s ease-out

      &:hover
        background-color: #e95739


</style> 