<template>
  <div id="app"><!--   
    <app-loading />  -->
    <div ref="transitioner" class="transitioner">
        <router-view/>
    </div>
    
    <transition name="fade" mode="out-in">
      <download-modal v-if="modal_state === MODAL_STATES.DOWNLOAD" />
      <feedback-modal v-if="modal_state === MODAL_STATES.FEEDBACK" />
    </transition>
  
  </div>
</template>

  
<script>

import { mapState, mapMutations } from 'vuex';
import DownloadModal from '@/components/modals/Download';
import FeedbackModal from '@/components/modals/Feedback'; 


// import Footer from '@/components/footer';
// import Menu from '@/components/menu';
//import Loading from '@/components/loading';  
// import Login from '@/components/login'; 

export default {

    name: 'App',

    data () {
        return {}
    },

    computed: {
      ...mapState('app', [
        'loading',
        'initialRoute',
        'CURSORS',
        'cursor',
        'loginPanelOpen',
        'menuOpen',

        'modal_state',
        'MODAL_STATES'
      ])
    },

    components: { 
      FeedbackModal,
      DownloadModal
    },

    mounted () {
      this.setup()
    },

  methods: {
 

    setup(){

      // disable touch move
      // window.addEventListener('touchmove', (e) => {e.preventDefault()}, {passive:false}); 
        
      // disable context menu
      document.addEventListener('contextmenu', function (e) {
        e.preventDefault();
      }, false);

      // disable tabbing
      document.addEventListener('keydown', function (e) {
        if (e.which == 9) {
            e.preventDefault();
        }
      }); 
    } 

  },
  watch: {

    'loading.completed' (val) {
      this.setup();
    } 
  } 
}


</script>
 
<style lang="sass">

  @import "src/styles/base.sass";

  #app
    margin: 0
    padding: 0
    +full()

    #app-inner
      margin: 0
      padding: 0
      position: absolute
      top: 4rem
      bottom: 4rem
      left: 9rem
      right: 9rem
      transition: right 0.3s ease-out 0.5s, left 0.3s ease-out 0.5s

      @media (max-width: $bp-large) 
        left: 4rem
        right: 4rem

  .fade-enter-active, 
  .fade-leave-active
    transition: opacity 1s

  .fade-enter, .fade-leave-to
    opacity: 0

            
</style>