<template> 

  <div id="ui-close-btn" v-on:click="onClick">

      <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
     viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
   
      <g id="Layer_2">
      </g>
      <g id="Layer_1">
        <line class="st0" x1="13.2" y1="87.2" x2="87" y2="13.3"/>
        <line class="st0" x1="87" y1="87.2" x2="13.2" y2="13.3"/>
      </g>
    </svg>
  </div>
</template>

<script>
 
import { mapState } from 'vuex'; 

export default {

    name: 'ui-close-btn',
 

    data () {
      return { }
    },

    computed: {},

    components: {},

    mounted () { },

    methods: {

      onClick() { 
        this.$emit('close');
      }
      
    },

    watch: {} 
} 
</script>


<style lang="sass" scoped>

  #ui-close-btn
    height: 30px
    width: 30px 
    color: #f6f3ee 
    cursor: pointer
    stroke: #5c564f
    stroke-width: 13
    stroke-miterlimit: 10
    transition: stroke 0.2s ease-out

    &:hover
      stroke: #601b43

    svg
      width: 100%
      height: 100%


</style>