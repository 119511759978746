<template>

  <div id="download-modal">

    <div class="inner">

      <transition-group>

        <div key="0" v-if="state === 'form'">
          <p class="title">
            Suggestions or Improvements? <br>
            Please leave your feedback.
          </p>
          <p class="subtitle">
            <a href="https://github.com/camoconnell/lazy-line-painter/issues" rel="noopener" target="_blank">If your reporting an issue, pls log it on github!</a>
          </p>

          <form v-on:submit="onSubmit">
     
            <div ref="name" class="option name-input">
              <p class="label">Name*</p>
              <div class="input">
                <input ref="input-name" type="text" name="name">
              </div>
            </div>

            <div ref="email" class="option email-input">
              <p class="label">Email*</p>
              <div class="input">
                <input ref="input-email" type="text" name="email">
              </div>
            </div>   

            <div ref="occupation" class="option">
              <p class="label">Occupation*</p>
              <div  class="input">
                <ui-options
                  v-bind:options="user_types"
                  v-bind:defaultOption="0"
                  v-on:change="onUserChange"
                  />
              </div>
            </div> 

            <div ref="message" class="message-option  option">
              <textarea ref="input-message" name="message" rows="6" cols="25" placeholder="Feedback *  "></textarea>
            </div>

            <div class="recaptca">This site is protected by reCAPTCHA and the Google 
              <a href="https://policies.google.com/privacy">Privacy Policy</a> and
              <a href="https://policies.google.com/terms">Terms of Service</a> apply.
            </div>

            <input ref="submit" class="download-btn" type="submit" value="Send">
          </form>
        </div>
  
        <div class="invalid-recaptcha" key="1" v-if="state === 'invalid-recaptcha'">
          <p>Sorry you can't use this form, <br> Google thinks your a bot!</p>
          <div class="recaptca">This site is protected by reCAPTCHA and the Google 
            <a href="https://policies.google.com/privacy">Privacy Policy</a> and
            <a href="https://policies.google.com/terms">Terms of Service</a> apply.
          </div>
        </div> 
 
        <div class="invalid-recaptcha" key="2" v-if="state === 'success'">
          <p>Sent successfully</p>
        </div> 
 
        <div class="invalid-recaptcha" key="3" v-if="state === 'error'">
          <p>Uh oh <br> Error happened</p>
        </div>

      </transition-group>

      <div class="close-btn">
        <ui-close-btn v-on:close="onClose" />
      </div> 

    </div>
  </div> 

</template>

<script>
 
import { mapState, mapMutations } from 'vuex';
import ResizeService from '@/services/ResizeService';  
import UiCloseBtn from '@/components/ui/close-btn';    
import UiOptions from '@/components/ui/options';
import axios from 'axios'
import qs from 'qs'

export default {

    name: 'feedback-modal',

    data () {

      return {

        RECAPTCHA_KEY: '6Lc8EY4UAAAAAA1ajD18paDWtznyA2KkMAIpFMMH',
        RECAPTCHA_ACTION: 'contact_form',
        RECAPTCHA_ENDPOINT: '/google-recaptcha-verification.php',

        state: 'form',
        user_type: null,
        user_types: [
          {
            label : 'Please Select',
            index: 0 
          },
          {
            label : 'Designer',
            index: 1 
          },
          {
            label : 'Developer',
            index: 2 
          },
          {
            label : 'Both',
            index: 3 
          },
          {
            label : 'Neither',
            index: 4 
          }
        ]
      }
    },

    computed: {
      ...mapState('app', [
        'modal_state',
        'MODAL_STATES',
        'recaptcha_result'
      ])
    },

    components: {
      UiCloseBtn,
      UiOptions
    },

    mounted () { },

    methods:{

      ...mapMutations('app', [
        'setModalState'
      ]),

      enter(){},
      
      leave(){},

      onUserChange(value) {
        this.user_type = this.user_types[value].label 
      },

      onSubmit(e){

        e.preventDefault()
        let valid = this.validate()
        if( valid){
          this.$refs.submit.classList.add('sending')
          setTimeout(this.post, 1000)
        } 
      },

      validate() {
  
        let name = this.$refs['input-name'].value
        let validName = name !== ''
        this.$refs.name.classList.toggle('invalid', !validName) 
        
        let validInterest = this.user_type && this.user_type !== this.user_types[0].label 
        this.$refs.occupation.classList.toggle('invalid', !validInterest) 
      
        let email = this.$refs['input-email'].value
        let validEmail = this.validateEmail(email)
        this.$refs.email.classList.toggle('invalid', !validEmail) 

        let message = this.$refs['input-message'].value
        let validMessage = message !== ''
        this.$refs.message.classList.toggle('invalid', !validMessage) 

        return (validName && validInterest && validMessage && validEmail)
      },

      validateEmail(email) {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
      },

      post(){
        grecaptcha.execute(this.RECAPTCHA_KEY, {
          action: this.RECAPTCHA_ACTION
        }).then(this.onTokenSuccess);
      },

      onTokenSuccess(token){
        axios({
          method: 'post',
          url: this.RECAPTCHA_ENDPOINT,
          data: qs.stringify({
            'recaptcha_response': token,
            'name': this.$refs['input-name'].value,
            'email': this.$refs['input-email'].value,
            'type': this.user_type,
            'message': this.$refs['input-message'].value
          })
        })
        .then(this.onFeedbackSuccess)
        .catch(this.onFeedbackError);
      },

      onFeedbackSuccess(status){
        if(status === 'STATUS_VERIFIED'){ 
          this.state = 'success' 
          setTimeout(()=>{
            this.setModalState(null);
          }, 3000)
        } else {
          this.error()
        }
      },

      onFeedbackError(){
        this.error()
      },

      onClose(){
        this.setModalState(null);
      },

      error(){
        this.state = 'error' 
        setTimeout(()=>{
          this.state = 'form'
        }, 3000)
      }
    },

    watch: {
      state(val){
        console.log(val)
      }
    } 
} 
</script>


<style scoped lang="sass">

  #download-modal
    padding: 2rem
    background: rgba(181, 40, 123, 0.9)
    width: 100%
    height: 100%
    color: #601b43
    text-transform: initial 
    position: fixed
    display: flex
    align-items: center
    justify-content: center
    z-index: 999

    input
      height: 100%

    textarea
      min-height: 18rem

    input,
    textarea
      color: #5c564f
      width: 100%
      border: none
      font-size: 16px
      padding: 5px
      font-size: 1.8rem
      font-family: 'melbournebold';


    .invalid
      p
        color: #de6565 !important

      textarea, input
        border: #de6565 solid 1px
        border-radius: 5px

        &::placeholder
          color: #de6565 !important


    .recaptca
      margin: 1.5rem 0

    .close-btn
      position: absolute
      top: 3rem
      right: 3rem

    .link-out
      svg
        height: 18px
        fill: #c5bbb1
        margin-bottom: -2px

    .inner
      background-color: #f7f3ed
      border-radius: 2rem
      padding: 5rem 8rem 13rem
      width: 590px
      position: relative 
      overflow: hidden

      p
        letter-spacing: 0.2rem
        font-size: 2.2rem
        line-height: 1.6
        font-family: 'melbournebold'
        color: #5c564f

        &.title
          margin-bottom: 1rem
 
        &.subtitle
          font-size: 1.8rem
          line-height: 1.4
          margin-bottom: 4rem

    .option
      display: flex
      margin-bottom: 0.5rem
      font-style: italic

      &.full-width
        display: block
        margin-bottom: 0.5rem

      .label
        width: 40%
        text-align: left
        font-size: 1.8rem

      .input
        flex-grow: 1

      &.message-option
        margin-top: 1rem

        

    .name-input,
    .email-input
      margin-bottom: 1rem
      font-style: italic
      height: 3rem
 
 
    .download-btn
      cursor: pointer
      position: absolute
      width: 100%
      bottom: 0
      left: 0
      text-transform: none
      font-size: 2.4rem
      letter-spacing: 2px
      font-family: 'melbournereg' 
      margin: 0
      height: 100px
      color: #fff2f2
      display: flex
      align-items: center
      justify-content: center
      border-radius: 1rem
      background: #de6565
      border-top-left-radius: 0
      border-top-right-radius: 0
      transition: background-color 0.3s ease-out, height 0.5s ease-in-out

      &:hover
        background-color: #e95739

      &.sending
        height : 100%

  .invalid-recaptcha
    background-color: #f7f3ed
    position: absolute
    width: 100%
    opacity: 0.96
    height: 100%
    top: 0
    left: 0
    display: flex
    align-items: center
    justify-content: center
    flex-direction: column

  .recaptca
    font-size: 1.2rem

</style> 