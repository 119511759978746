import { render, staticRenderFns } from "./close-btn.vue?vue&type=template&id=4f5d89e3&scoped=true&"
import script from "./close-btn.vue?vue&type=script&lang=js&"
export * from "./close-btn.vue?vue&type=script&lang=js&"
import style0 from "./close-btn.vue?vue&type=style&index=0&id=4f5d89e3&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4f5d89e3",
  null
  
)

component.options.__file = "close-btn.vue"
export default component.exports